import { login, dashboard, settings, supportTickets, users, payments, subscriptionsPlans, analytics } from "./routesDefined";

import { images } from "../assets/styles/Images";

const routes = [
  {
    id: "1",
    path: dashboard, // the url
    icon: images.Dashboard_Icon, // the icon
    name: "Dashboard", // name that appear in Sidebar
  },
  {
    id: "2",
    path: analytics,
    icon: images.Analytics_Icon, // the icon
    name: "Analytics", // name that appear in Sidebar
  },
  {
    id: "3",
    path: settings,
    icon: images.Settings_Icon, // the icon
    name: "Settings", // name that appear in Sidebar
  },
  {
    id: "4",
    path: supportTickets, // the url
    icon: images.SupportTicket_Icon, // the icon
    name: "Support Tickets", // name that appear in Sidebar
  },
  {
    id: "5",
    path: users, // the url
    icon: images.Users_Icon, // the icon
    name: "Users", // name that appear in Sidebar
  },
  {
    id: "6",
    path: payments, // the url
    icon: images.Payments_Icon, // the icon
    name: "Payments", // name that appear in Sidebar
  },
  {
    id: "7",
    path: subscriptionsPlans, // the url
    icon: images.Subscription_Icon, // the icon
    name: "Subscriptions Plans", // name that appear in Sidebar
  },
  {
    id: "11",
    path: "",
    icon: "",
    name: "",
  },
];

export default routes;

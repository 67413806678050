import moment from "moment";
import Lottie from "react-lottie";
import Badge from "../components/Badge";
import { Tooltip } from "react-tooltip";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import { mediaUrl } from "../utils/config";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { images } from "../assets/styles/Images";
import TitleValue from "../components/TitleValue";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import StatusFilter from "../components/StatusFilter";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import ConfirmationModal from "../components/ConfirmationModal";
import NotificationCircle from "../components/NotificationCircle";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import AddNewSettingModal from "../components/AddNewSettingModal";
import TransactionHistoryModal from "../components/TransactionHistoryModal";
import { ArrowPathIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

// Table Head
const TABLE_HEAD = ["Setting Name", "Contact Person", "Current Plan", "Next Payment", "Employees", "Joined at", "Last login at", "Status"];
const TABLE_HEAD_PENDING = ["Setting Name", "Contact Person", "Employees", "Created at", "Action"];
const TABLE_HEAD_PENDING_WithOutAction = ["Setting Name", "Contact Person", "Employees", "Created at"];
const TABLE_HEAD_TRANSACTION = ["Amount", "Package"];
const TABLE_HEAD_REJECT = ["Setting Name", "Contact Person", "Employees", "Created at", "Action"];
// Options
const options = [
  { value: "all", label: "All" },
  { value: "active", label: "Active Settings" },
  { value: "deactive", label: "Deactive Settings" },
];

const Settings = () => {
  // Context
  const {
    user,
    loading,
    setLoading,
    setBtnLoading,
    openSuccessModal,
    getPendingSettingsGlobal,
    // getPendingSettings,
    pendingCount,
    // pendingSettingsListing,
    // totalPagePendingSettings,
    // currentPagePendingSettings,
    // setCurrentPagePendingSettings,
  } = useLoggedContext();

  // React Router Dom Hooks
  const navigate = useNavigate();

  // Use States

  // Active row / tabs
  const [activeTab, setActiveTab] = useState(0);
  const [activeRow, setActiveRow] = useState(null);
  const [historyLoading, setHistoryLoading] = useState(false);

  // Select
  const [statusOption, setStatusOption] = useState(options[1]);

  // Data
  const [searchText, setSearchText] = useState("");
  const [settingEditData, setSettingEditData] = useState("");
  const [allSettingsListing, setAllSettingsListing] = useState([]);
  console.log("🚀 ~ Settings ~ allSettingsListing:", allSettingsListing)
  const [pendingSettingsListing, setPendingSettingsListing] = useState([]);
  const [rejectSettingListing, setRejectSettingListing] = useState([]);

  // Pagination
  const [totalPageAllSettings, setTotalPageAllSettings] = useState(0);
  const [currentPageAllSettings, setCurrentPageAllSettings] = useState(1);
  const [totalPagePendingSettings, setTotalPagePendingSettings] = useState(0);
  const [currentPagePendingSettings, setCurrentPagePendingSettings] = useState(1);
  const [totalPageRejectSettings, setTotalPageRejectSettings] = useState(0);
  const [currentPageRejectSettings, setCurrentPageRejectSettings] = useState(1);
  // Modal
  const [addNewModal, setAddNewModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState({ open: false, name: "" });
  const [openAcceptDeclineModal, setOpenAcceptDeclineModal] = useState({ open: false, label: "", name: "" });
  const [openTransactionHistoryModal, setOpenTransactionHistoryModal] = useState({ open: false, data: null });
  const [openSettingDetailModal, setOpenSettingDetailModal] = useState({ open: false, data: null });

  // Active Tab
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  let selectedListing = activeTab === 0 ? allSettingsListing : activeTab === 1 ? pendingSettingsListing : rejectSettingListing;
  const detail = selectedListing?.find((item) => item?.id === (activeRow || selectedListing[0]?.id));

  const transactions = detail?.user?.transaction;

  // console.log(transactions);

  // <------- Table Sorting ----------->

  // Active Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(allSettingsListing);

  // Pending Table Sorting
  const {
    items: pendingSettingList,
    requestSort: pendingSettingListSort,
    sortConfig: pendingSettingListSortConfig,
  } = useSortableData(pendingSettingsListing);

  // Reject Table Sorting
  const {
    items: rejectSettingList,
    requestSort: rejectSettingListSort,
    sortConfig: rejectSettingListSortConfig,
  } = useSortableData(rejectSettingListing);

  // Transaction History Table Sorting
  const {
    items: transactionHistoryList,
    requestSort: transactionHistoryListSort,
    sortConfig: transactionHistoryListSortConfig,
  } = useSortableData(transactions);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 9 : isLaptopMedium ? 6 : isLaptop ? 5 : 10;

  // Get all settings list
  const getAllSettings = async (emptySearch) => {
    setLoading(true);
    let payload = {
      search: emptySearch ? "" : searchText,
      page: currentPageAllSettings,
      records_no: itemsPerPage,
      is_blocked: statusOption?.value === "active" ? 1 : statusOption?.value === "deactive" ? 0 : "",
    };

    try {
      const res = await ApiServices.settings.getSettings(payload);
      const { data, message } = res;
      // console.log("Setting Lists", data);
      if (data.code === 200) {
        setLoading(false);
        setAllSettingsListing(data?.data?.data);
        setCurrentPageAllSettings(data?.data?.current_page);
        setTotalPageAllSettings(Math.ceil(data?.data?.total / data?.data?.per_page));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Pending Settings Count
  const getPendingSettings = async (emptySearch) => {
    setLoading(true);
    let payload = {
      search: emptySearch ? "" : searchText,
      page: currentPagePendingSettings,
      records_no: itemsPerPage,
      status: "Pending",
    };

    try {
      const res = await ApiServices.settings.getSettings(payload);
      const { data, message } = res;
      // console.log("Pending Setting Lists", data);

      if (data.code === 200) {
        setLoading(false);
        setPendingSettingsListing(data?.data?.data);
        setCurrentPagePendingSettings(data?.data?.current_page);
        setTotalPagePendingSettings(Math.ceil(data?.data?.total / data?.data?.per_page));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Reject Settings Count
  const getRejectSettings = async (emptySearch) => {
    setLoading(true);
    let payload = {
      search: emptySearch ? "" : searchText,
      page: currentPageRejectSettings,
      records_no: itemsPerPage,
      status: "Reject",
    };

    try {
      const res = await ApiServices.settings.getSettings(payload);
      const { data, message } = res;

      if (data.code === 200) {
        setLoading(false);
        setRejectSettingListing(data?.data?.data);
        setCurrentPageRejectSettings(data?.data?.current_page);
        setTotalPageRejectSettings(Math.ceil(data?.data?.total / data?.data?.per_page));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Block Setting
  const blockSetting = () => {
    setBtnLoading(true);
    let id = detail?.user?.id;
    let settingMessage = detail?.user?.status === 0 ? `${openBlockModal.name} has been Activated` : `${openBlockModal.name} has been Deactivated`;

    ApiServices.settings
      .blockSetting(id)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          getAllSettings();
          setOpenBlockModal({ open: false, name: "" });
          openSuccessModal({
            title: "Success!",
            message: settingMessage,
            onPress: (close) => {
              close();
              getAllSettings();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Accept Decline
  const acceptDeclineSetting = () => {
    setBtnLoading(true);
    let settingMessage =
      openAcceptDeclineModal.label === "accept"
        ? `${openAcceptDeclineModal.name} has been Activated`
        : `${openAcceptDeclineModal.name} has been Declined`;
    let settingStatus = openAcceptDeclineModal.label === "accept" ? `Accept` : `Reject`;
    let settingId = detail?.user?.id;

    let formData = new FormData();
    formData.append("status", settingStatus);
    formData.append("id", settingId);

    ApiServices.settings
      .acceptDeclineSetting(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          setActiveTab(0);
          getAllSettings();
          getPendingSettingsGlobal();
          getPendingSettings();
          getRejectSettings();
          setOpenAcceptDeclineModal({ open: false, label: "" });
          openSuccessModal({
            title: "Success!",
            message: settingMessage,
            onPress: (close) => {
              close();
              setActiveTab(0);
              getAllSettings();
              getPendingSettingsGlobal();
              getPendingSettings();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        if (activeTab === 0) {
          await getAllSettings();
          setCurrentPageAllSettings(1);
        } else {
          await getPendingSettings();
          setCurrentPagePendingSettings(1);
        }
      }
    }
  };

  // useEffects
  useEffect(() => {
    if (activeTab === 0) {
      setActiveRow(items[0]?.id);
    } else if (activeTab === 1) {
      setActiveRow(pendingSettingList[0]?.id);
    } else {
      setActiveRow(rejectSettingList[0]?.id);
    }
  }, [activeTab, items, pendingSettingList, rejectSettingList]);

  useEffect(() => {
    getAllSettings();
  }, [currentPageAllSettings, statusOption]);

  useEffect(() => {
    getPendingSettings();
  }, [currentPagePendingSettings]);

  useEffect(() => {
    getRejectSettings();
  }, [currentPageRejectSettings]);

  useEffect(() => {
    getPendingSettingsGlobal();
  }, []);
  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // <-------- pagination ----------->

  // All Settings Listing
  const handleAllSettingsPageChange = ({ selected }) => {
    setCurrentPageAllSettings(selected + 1);
  };

  // // Pending Settings Listing
  const handlePendingSettingsPageChange = ({ selected }) => {
    setCurrentPagePendingSettings(selected + 1);
  };
  // Reject Settings Listing
  const handleRejectSettingsPageChange = ({ selected }) => {
    setCurrentPageRejectSettings(selected + 1);
  };

  // "super_panel_accept_decline_request",
  // "super_panel_block_unblock_setting",
  // "super_panel_add_new_setting",
  // "super_panel_edit_setting",

  const hasPermissions = user?.permissions?.includes("super_panel_accept_decline_request") || user?.role === "super_admin";

  const calculateTimeDifference = (endDateUnix) => {
    if (endDateUnix) {
      // Get current date in UNIX timestamp
      const currentDateUnix = Math.floor(Date.now() / 1000);

      // Calculate the difference in seconds
      const differenceSeconds = endDateUnix - currentDateUnix;

      // Convert seconds to days
      const differenceInDays = Math.floor(differenceSeconds / (60 * 60 * 24));

      return differenceInDays;
    } else {
      return null;
    }
  };

  // console.log({ detail });

  return (
    <>
      <div className="grid grid-cols-12 gap-4 ">
        <div className="col-span-12 ">
          <div className="card h-[81vh] 3xl:h-[84vh]">
            <div className="mb-6">
              <div className="sticky top-0 z-[2] flex w-full flex-col items-start justify-between gap-x-6 bg-white md:flex-row md:items-center">
                <div className="flex gap-x-3 3xl:gap-x-6">
                  <h3
                    className={`cursor-pointer text-base font-medium 3xl:text-lg ${
                      activeTab === 0 ? "border-b-2 border-secondary text-secondary" : "text-gray-500"
                    }`}
                    onClick={() => handleTabClick(0)}
                  >
                    All
                  </h3>
                  <div className="flex items-center gap-x-2">
                    <h3
                      className={`cursor-pointer text-base font-medium 3xl:text-lg ${
                        activeTab === 1 ? "border-b-2 border-secondary text-secondary" : "text-gray-500"
                      }`}
                      onClick={() => handleTabClick(1)}
                    >
                      Pending
                    </h3>
                    {pendingCount !== 0 && <NotificationCircle notificationCount={pendingCount} />}
                  </div>
                  <div className="flex items-center gap-x-2">
                    <h3
                      className={`cursor-pointer text-base font-medium 3xl:text-lg ${
                        activeTab === 2 ? "border-b-2 border-secondary text-secondary" : "text-gray-500"
                      }`}
                      onClick={() => handleTabClick(2)}
                    >
                      Rejected
                    </h3>

                    <ArrowPathIcon
                      onClick={() => {
                        getAllSettings();
                        getPendingSettings();
                        getRejectSettings();
                        getPendingSettingsGlobal();
                      }}
                      className="mt-1 h-5 w-5 cursor-pointer text-grayColor300"
                    />
                  </div>
                </div>

                {/* Search */}

                <div className="flex items-center justify-center gap-x-2 md:mt-0 2xl:gap-x-4">
                  {(user?.permissions?.includes("super_panel_add_new_setting") || user?.role === "super_admin") && (
                    <button
                      className="mt-0.5 w-32 rounded-full bg-secondary py-2.5 text-sm text-white"
                      type="button"
                      onClick={() => setAddNewModal(true)}
                    >
                      Add New
                    </button>
                  )}
                  {activeTab === 0 && <StatusFilter settingsScreen selected={statusOption} setSelected={setStatusOption} options={options} />}
                  <form>
                    <div className="relative flex items-center ">
                      <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                        <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                      </div>

                      <input
                        type="search"
                        id="search"
                        name="search"
                        placeholder="Search..."
                        autoComplete="off"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          if (e.target.value.trim() === "") {
                            activeTab === 0 ? getAllSettings(true) : activeTab === 1 ? getPendingSettings(true) : getRejectSettings(true);
                          }
                        }}
                        onKeyDown={handleSearch}
                        className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2.5 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                      />
                    </div>
                  </form>
                  {/* select */}
                </div>
              </div>
              <Heading subheading="Details of active settings & pending for approval.  " />
            </div>

            {activeTab === 0 ? (
              <>
                <div className="-mx-6 mb-8 overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-gray-100 bg-white p-4 first:pl-6"
                            onClick={() => {
                              let sortKey;
                              if (head === "Setting Name") {
                                sortKey = "name";
                              } else if (head === "Contact Person") {
                                sortKey = "contact_person_name";
                              } else if (head === "Current Plan") {
                                sortKey = "setting.plan_name";
                              } else if (head === "Next Payment") {
                                sortKey = "setting.end_date";
                              } else if (head === "Joined at") {
                                sortKey = "created_at";
                              } else if (head === "Last login at") {
                                sortKey = "setting.created_at_unix";
                              } else if (head === "Status") {
                                sortKey = "item.user.status";
                              } else {
                                sortKey = head.toLowerCase();
                              }
                              requestSort(sortKey);
                            }}
                          >
                            <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                              {head}
                              {sortConfig.key ===
                                (head === "Setting Name"
                                  ? "name"
                                  : head === "Contact Person"
                                    ? "contact_person_name"
                                    : head === "Current Plan"
                                      ? "setting.plan_name"
                                      : head === "Next Payment"
                                        ? "setting.end_date"
                                        : head === "Status"
                                          ? "item.user.status"
                                          : head === "Joined at"
                                            ? "created_at"
                                            : head === "Last login at"
                                              ? "setting.created_at_unix"
                                              : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                              ) : (
                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                              )}
                            </p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="9">
                            <Skeleton count={itemsPerPage} height={50} />
                          </td>
                        </tr>
                      ) : items.length > 0 ? (
                        items.map((item, index) => (
                          <tr key={item?.id} className=" cursor-pointer hover:bg-gray-50">
                            <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                              <div
                                onClick={() =>
                                  navigate(item?.user?.id ? `${item?.user?.id}` : "1", {
                                    state: { settingDetailData: item },
                                  })
                                }
                                className="flex cursor-pointer items-center"
                              >
                                <div className="mr-3 h-8 w-8 ">
                                  {/* <CustomImage placeholderImg={Images.PLACEHOLDER} imgSource={userUrl + item?.profile_url} alt="profile img" /> */}
                                  <img
                                    className="mr-3 h-8 w-8 object-contain"
                                    src={item?.logo ? mediaUrl + item?.logo : images.placeholder}
                                    alt="Profile"
                                  />
                                </div>
                                <p className="text-xs font-normal text-primary-color-200 hover:underline 3xl:text-sm">{item?.name}</p>
                              </div>
                            </td>
                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.contact_person_name}</p>
                            </td>
                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {item?.user?.subscription[0]?.plan_name === " " || item?.user?.subscription[0]?.plan_name === null ? (
                                  "N/A"
                                ) : (
                                  <Badge status={item?.user?.subscription[0]?.plan_name} />
                                )}
                              </p>
                              {item?.user?.free_trial === 1 && (
                                <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                  {item?.user?.subscription[0]?.end_date && (
                                    <p className="text-xs font-normal text-primary-color-200 ">
                                      {calculateTimeDifference(item?.user?.subscription[0]?.end_date)} days free trial remaining
                                    </p>
                                  )}
                                </p>
                              )}
                            </td>
                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {item?.user?.subscription[0]?.end_date
                                  ? `£${item?.user?.subscription[0]?.price} on ${moment.unix(item?.user?.subscription[0]?.end_date).format("D MMM YYYY")}`
                                  : "N/A"}
                              </p>
                            </td>
                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.users_count}</p>
                            </td>

                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {moment.unix(item?.created_at).format("D MMM YYYY")}
                              </p>
                              <Tooltip
                                anchorSelect={`#date${index + 1}`}
                                id={`date-tooltip${index + 1}`}
                                offset={5}
                                className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                classNameArrow="hidden"
                                effect="solid"
                                place="bottom"
                              >
                                {/* Tooltip Content */}
                                <span>{moment.unix(item?.created_at).format("D MMM YYYY h:mm A")}</span>
                              </Tooltip>
                            </td>

                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {item?.last_logged_in?.created_at_unix
                                  ? moment.unix(item?.last_logged_in?.created_at_unix).format("D MMM YYYY h:mm A")
                                  : "N/A"}
                              </p>
                            </td>

                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <Badge status={item?.user?.status === 0 ? "Deactive" : "Active"} />
                            </td>
                          </tr>
                        ))
                      ) : (
                        // Render "No Data" message
                        <tr className="h-96 3xl:h-[500px]">
                          <td colSpan="6">
                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="absolute bottom-4">
                  <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    nextClassName="item next"
                    renderOnZeroPageCount={null}
                    breakClassName="item break-me "
                    containerClassName="pagination"
                    previousClassName="item previous"
                    pageCount={totalPageAllSettings}
                    pageClassName="item pagination-page"
                    forcePage={currentPageAllSettings - 1}
                    onPageChange={handleAllSettingsPageChange}
                    nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                    previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                  />
                </div>
              </>
            ) : activeTab === 1 ? (
              <>
                <div className="-mx-6 mb-8 overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        {(hasPermissions ? TABLE_HEAD_PENDING : TABLE_HEAD_PENDING_WithOutAction).map((head) => (
                          <th
                            key={head}
                            className="border-b border-gray-100 bg-white p-4 first:pl-6"
                            onClick={() => {
                              let sortKey;
                              if (head === "Setting Name") {
                                sortKey = "name";
                              } else if (head === "Contact Person") {
                                sortKey = "contact_person_name";
                              } else if (head === "Created at") {
                                sortKey = "created_at";
                              } else {
                                sortKey = head.toLowerCase();
                              }
                              pendingSettingListSort(sortKey);
                            }}
                          >
                            <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                              {head}
                              {pendingSettingListSortConfig.key ===
                                (head === "Setting Name"
                                  ? "name"
                                  : head === "Contact Person"
                                    ? "contact_person_name"
                                    : head === "Created at"
                                      ? "created_at"
                                      : head.toLowerCase()) && pendingSettingListSortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                              ) : (
                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                              )}
                            </p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Skeleton count={5} height={50} />
                          </td>
                        </tr>
                      ) : pendingSettingList.length > 0 ? (
                        pendingSettingList.map((item, index) => (
                          <tr key={item?.id} className=" hover:bg-gray-50">
                            <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                              <div
                                onClick={() =>
                                  navigate(item?.user?.id ? `${item?.user?.id}` : "1", {
                                    state: { settingDetailData: item },
                                  })
                                }
                                className="flex cursor-pointer items-center"
                              >
                                <div className="mr-3 h-8 w-8 ">
                                  {/* <CustomImage placeholderImg={Images.PLACEHOLDER} imgSource={userUrl + item?.profile_url} alt="profile img" /> */}
                                  <img
                                    className="mr-3 h-8 w-8 object-contain"
                                    src={item?.logo ? mediaUrl + item?.logo : images.placeholder}
                                    alt="Profile"
                                  />
                                </div>
                                <p className="text-xs font-normal text-primary-color-200 hover:underline 3xl:text-sm">{item?.name}</p>
                              </div>
                            </td>
                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.contact_person_name}</p>
                            </td>
                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.users_count}</p>
                            </td>

                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {moment.unix(item?.created_at).format("D MMM YYYY")}
                              </p>
                              <Tooltip
                                anchorSelect={`#date${index + 1}`}
                                id={`date-tooltip${index + 1}`}
                                offset={5}
                                className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                classNameArrow="hidden"
                                effect="solid"
                                place="bottom"
                              >
                                {/* Tooltip Content */}
                                <span>{moment.unix(item?.created_at).format("D MMM YYYY h:mm A")}</span>
                              </Tooltip>
                            </td>
                            {hasPermissions && (
                              <td className="py-3 pl-4 pr-3 3xl:px-4">
                                <div className="flex items-center gap-x-3">
                                  <span
                                    onClick={() => setOpenAcceptDeclineModal({ open: true, label: "accept", name: item?.name })}
                                    className="cursor-pointer text-xs font-normal text-accent underline 3xl:text-sm"
                                  >
                                    Accept
                                  </span>
                                  <span
                                    onClick={() => setOpenAcceptDeclineModal({ open: true, label: "decline", name: item?.name })}
                                    className="cursor-pointer text-xs font-normal text-red-500 underline 3xl:text-sm"
                                  >
                                    Decline
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        // Render "No Data" message
                        <tr className="h-96 3xl:h-[500px]">
                          <td colSpan="6">
                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="absolute bottom-4">
                  <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    nextClassName="item next"
                    renderOnZeroPageCount={null}
                    breakClassName="item break-me "
                    containerClassName="pagination"
                    previousClassName="item previous"
                    pageClassName="item pagination-page "
                    pageCount={totalPagePendingSettings}
                    forcePage={currentPagePendingSettings - 1}
                    onPageChange={handlePendingSettingsPageChange}
                    nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                    previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="-mx-6 mb-8 overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD_REJECT.map((head) => (
                          <th
                            key={head}
                            className="border-b border-gray-100 bg-white p-4 first:pl-6"
                            onClick={() => {
                              let sortKey;
                              if (head === "Setting Name") {
                                sortKey = "name";
                              } else if (head === "Contact Person") {
                                sortKey = "contact_person_name";
                              } else if (head === "Created at") {
                                sortKey = "created_at";
                              } else {
                                sortKey = head.toLowerCase();
                              }
                              rejectSettingListSort(sortKey);
                            }}
                          >
                            <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                              {head}
                              {rejectSettingListSortConfig.key ===
                                (head === "Setting Name"
                                  ? "name"
                                  : head === "Contact Person"
                                    ? "contact_person_name"
                                    : head === "Created at"
                                      ? "created_at"
                                      : head.toLowerCase()) && rejectSettingListSortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                              ) : (
                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                              )}
                            </p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Skeleton count={5} height={50} />
                          </td>
                        </tr>
                      ) : rejectSettingList.length > 0 ? (
                        rejectSettingList.map((item, index) => (
                          <tr key={item?.id} className=" hover:bg-gray-50">
                            <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                              <div
                                onClick={() =>
                                  navigate(item?.user?.id ? `${item?.user?.id}` : "1", {
                                    state: { settingDetailData: item },
                                  })
                                }
                                className="flex cursor-pointer items-center"
                              >
                                <div className="mr-3 h-8 w-8 ">
                                  {/* <CustomImage placeholderImg={Images.PLACEHOLDER} imgSource={userUrl + item?.profile_url} alt="profile img" /> */}
                                  <img
                                    className="mr-3 h-8 w-8 object-contain"
                                    src={item?.logo ? mediaUrl + item?.logo : images.placeholder}
                                    alt="Profile"
                                  />
                                </div>
                                <p className="text-xs font-normal text-primary-color-200 hover:underline 3xl:text-sm">{item?.name}</p>
                              </div>
                            </td>
                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.contact_person_name}</p>
                            </td>
                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.users_count}</p>
                            </td>

                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {moment.unix(item?.created_at).format("D MMM YYYY")}
                              </p>
                              <Tooltip
                                anchorSelect={`#date${index + 1}`}
                                id={`date-tooltip${index + 1}`}
                                offset={5}
                                className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                classNameArrow="hidden"
                                effect="solid"
                                place="bottom"
                              >
                                {/* Tooltip Content */}
                                <span>{moment.unix(item?.created_at).format("D MMM YYYY h:mm A")}</span>
                              </Tooltip>
                            </td>
                            {hasPermissions && (
                              <td className="py-3 pl-4 pr-3 3xl:px-4">
                                <div className="flex items-center gap-x-3">
                                  <span
                                    onClick={() => setOpenAcceptDeclineModal({ open: true, label: "accept", name: item?.name })}
                                    className="cursor-pointer text-xs font-normal text-accent underline 3xl:text-sm"
                                  >
                                    Accept
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        // Render "No Data" message
                        <tr className="h-96 3xl:h-[500px]">
                          <td colSpan="6">
                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="absolute bottom-4">
                  <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    nextClassName="item next"
                    renderOnZeroPageCount={null}
                    breakClassName="item break-me "
                    containerClassName="pagination"
                    previousClassName="item previous"
                    pageClassName="item pagination-page "
                    pageCount={totalPageRejectSettings}
                    forcePage={currentPageRejectSettings - 1}
                    onPageChange={handleRejectSettingsPageChange}
                    nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                    previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Block Modal */}
      <ConfirmationModal
        isOpen={openBlockModal.open}
        handleSubmit={blockSetting}
        setIsOpen={(open) => setOpenBlockModal((prev) => ({ ...prev, open }))}
        label={detail?.user?.status === 1 ? `Deactivate ${detail?.name}` : `Activate ${detail?.name}`}
      />

      {/* Accept Decline Modal */}
      <ConfirmationModal
        isOpen={openAcceptDeclineModal.open}
        label={openAcceptDeclineModal.label}
        name={openAcceptDeclineModal.name}
        handleSubmit={acceptDeclineSetting}
        setIsOpen={(open) => setOpenAcceptDeclineModal((prev) => ({ ...prev, open }))}
      />

      {/* Transaction History Modal */}
      <TransactionHistoryModal
        isOpen={openTransactionHistoryModal.open}
        data={openTransactionHistoryModal.data}
        setIsOpen={(open) => setOpenTransactionHistoryModal((prev) => ({ ...prev, open }))}
      />

      {/* Add New Setting Modal */}
      <AddNewSettingModal
        isOpen={addNewModal}
        setActiveTab={setActiveTab}
        getListing={getAllSettings}
        onSuccess={() => {
          getPendingSettingsGlobal();
          getPendingSettings();
        }}
        settingEditData={settingEditData}
        setSettingEditData={setSettingEditData}
        setIsOpen={() => setAddNewModal(false)}
      />
    </>
  );
};

export default Settings;

import ApiServices from "../api/services";
import { login } from "../routes/routesDefined";
import { images } from "../assets/styles/Images";
import React, { useState, useEffect } from "react";
import { useLoggedContext } from "../context/provider";
import { useNavigate, useParams } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

const ResetPassword = () => {
  let navigate = useNavigate();

  const params = useParams();

  const { openSuccessModal } = useLoggedContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [token, setToken] = useState("");

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { password, confirmPassword } = values;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    setToken(params.token);
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    const payload = {
      token: token,
      password: password,
    };

    ApiServices.auth
      .resetPassword(payload)
      .then((response) => {
        if (response.data.code === 200) {
          setLoading(false);
          openSuccessModal({
            title: "Success!",
            message: response.data.message,
            onPress: (close) => {
              close();
              navigate("/" + login, { replace: false });
            },
          });
          setError("");
        } else {
          setLoading(false);
          setError("Invalid User");
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="relative flex h-screen items-center overflow-hidden bg-white">
      <div className="h-screen w-full bg-white px-6 sm:px-8 md:px-16 lg:w-1/2">
        <div className="mx-auto flex h-[100%] w-full flex-col justify-center sm:w-[70%] 2xl:w-[60%]">
          <h4 className="d-flex mb-8 w-full text-center text-xl font-bold uppercase tracking-wider text-white text-primary lg:text-start 2xl:mb-12">
            <img src={images.NewLogo} alt="edufeel Social Logo" className="mx-auto w-[15rem] lg:mx-0 2xl:w-[19.4rem]" />
          </h4>
          <div>
            <div className="text-grayColor500 mb-2 text-center text-lg font-semibold leading-tight lg:text-start 2xl:text-xl">
              Reset Your Password
            </div>
            <p className="text-md mb-8 text-center font-normal leading-normal text-grayColor300 lg:mb-10 lg:text-start lg:leading-snug 2xl:mb-12">
              Enter your new password.
            </p>
          </div>
          <form onSubmit={handleFormSubmit} className="mx-auto w-full" autoComplete="off">
            <div className="mb-4">
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  className={`text-grayColor500 text-md flex w-full items-center rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:text-base 2xl:leading-loose`}
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  autoComplete="off"
                  required
                />
                <i
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-5 top-[50%] translate-y-[-50%] cursor-pointer text-gray-50"
                >
                  {" "}
                  {showPassword ? <EyeIcon className="h-4 w-4 text-secondary" /> : <EyeSlashIcon className="h-4 w-4 text-secondary" />}
                </i>
              </div>
            </div>

            <div className="mb-4">
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={handleChange}
                  className={`text-grayColor500 text-md flex w-full items-center rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:text-base 2xl:leading-loose`}
                  id="confirm-password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  autoComplete="off"
                  required
                />
                <i
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-5 top-[50%] translate-y-[-50%] cursor-pointer text-gray-50"
                >
                  {" "}
                  {showConfirmPassword ? <EyeIcon className="h-4 w-4 text-secondary" /> : <EyeSlashIcon className="h-4 w-4 text-secondary" />}
                </i>
              </div>
              {error.length >= 1 && <span className="mt-2 mb-8 text-red-500">{error}</span>}
            </div>

            <div className="mt-5 flex items-center justify-center lg:mt-6 2xl:mt-10">
              <button className={`w-full rounded-full bg-secondary py-4 px-4 text-base font-medium leading-loose text-white shadow-md xl:text-lg `}>
                {!loading && <span>Reset your password</span>}
                {loading && (
                  <svg
                    role="status"
                    className="inline h-6 w-6 animate-spin fill-gray-600 text-gray-200"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden h-screen w-full bg-white sm:p-8 lg:block lg:w-1/2 lg:p-8 2xl:p-16">
        <img
          src={images.authImgSvg}
          className="ml-auto h-full w-full rounded-3xl object-cover "
          style={{ objectPosition: "50% 5%" }}
          alt="login img"
        />
      </div>
    </div>
  );
};

export default ResetPassword;

import React from "react";
import { images } from "../assets/styles/Images";

const Badge = ({ status, crown , label}) => {
  const lowerCaseStatus = status?.toLowerCase();

  const badgeColors = {
    basic:"bg-[#FEF9F2] text-[#F5B866]",
    premium: "bg-[#EAFCF3] text-[#53E89E]",
    enterprise: "bg-[#FFEFEB] text-[#F38E74]",
    active: "bg-[#1F8D6026] text-accent",
    received: "bg-[#1F8D6026] text-accent",
    accept: "bg-[#1F8D6026] text-accent",
    block: "bg-[#FE4A4933] text-red-500",
    failed: "bg-[#FE4A4933] text-red-500",
    open: "bg-accent text-white",
    closed: "bg-[#FE4A49] text-white",
    deactive: "bg-red-200 text-red-400",
    admin: "bg-[#30303026] text-black",
    author: "bg-[#FFBC0926] text-[#FFBC09]",
    pending: "bg-[#FFBC0926] text-[#FFBC09]"
  };

  return (
    <div className={`rounded-full  ${label ? "w-48" : "w-20"} flex  justify-center py-1  ${badgeColors[lowerCaseStatus]}`}>
      <div>{lowerCaseStatus === "author" && <img src={images.Crown} className="w-3.5 h-3.5" />}</div>
      <div className="text-xs font-semibold capitalize ">{ label ? label :status}</div>
    </div>
  );
};

export default Badge;
  
import React from "react";
import Badge from "./Badge";
import Heading from "./Heading";
import { images } from "../assets/styles/Images";
import { Tooltip } from "react-tooltip";

const TicketDetail = ({ heading, subheading, ticketNo, status, date, dateWithTime, companyIcon, companyName, comment, activeColor }) => {
  return (
    <div
      className={`${activeColor && activeColor} flex min-h-[200px] cursor-pointer flex-col space-y-4 divide-y-2 divide-grayColor200 rounded-2xl border p-3 2xl:p-4`}
    >
      <div className="flex-grow">
        <Heading heading={heading} subheading={subheading} inlineTitle={ticketNo} />
      </div>
      <div className="flex items-center justify-between pt-4">
        <div className="flex items-center gap-x-2">
          <Badge status={status} />
          <h2 className="text-xs text-primary-color-200 2xl:text-sm">
            <span className="pr-1 font-semibold ">
              <img src={companyIcon} alt="Company" className="inline-block w-6 h-6 rounded-full" /> {companyName}
            </span>
            opened on
            <span id="ticketopendate" className="pl-1 font-semibold">
              {date}
            </span>
            <Tooltip
              anchorSelect="#ticketopendate"
              id="ticketopendate-tooltip"
              offset={5}
              className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
              classNameArrow="hidden"
              effect="solid"
              place="bottom"
            >
              {/* Tooltip Content */}
              <span>{dateWithTime}</span>
            </Tooltip>
          </h2>
        </div>
        <div className="flex items-center gap-x-1">
          <img src={images.Comment} alt="Comment" />
          <h2 className="text-xs font-medium text-primary-color-200">{comment}</h2>
        </div>
      </div>
    </div>
  );
};

export default TicketDetail;

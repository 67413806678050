import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useState, useRef, useEffect } from "react";

const SupportFilter = ({ options, onSelect }) => {
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  // Use effect to handle clicks outside the dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = filterText === "" ? options : options.filter((option) => option.label.toLowerCase().includes(filterText.toLowerCase()));

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleOptionSelect = (option) => {
    onSelect(option);
    setSelectedOption(option);
    setIsOpen(false);
    setFilterText("");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFilterText("");
    }
  };

  return (
    <Popover className="relative" as="div">
      <div className="lg:slide-effect" ref={dropdownRef}>
        <Popover.Button
          className={`link group inline-flex w-15 items-center justify-between gap-x-2  text-sm font-medium leading-5 text-black focus:outline-0 focus:outline-offset-0 `}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{selectedOption ? selectedOption.label : <span className="text-primary-color-100 font-normal">Settings</span>}</span>

          <ChevronDownIcon className={`-mr-1 inline-block h-4 w-4 text-primary-color-100`} aria-hidden="true" />
        </Popover.Button>
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-8"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-8"
        >
          <Popover.Panel className="absolute left-0 top-6 z-10 mt-3 w-56 transform px-4 sm:px-0 ">
            <div className="overflow-hidden rounded-lg shadow-xl">
              <div className="relative flex flex-col items-center space-y-4 bg-white py-4 ">
                <input
                  type="text"
                  className="mx-auto w-52 rounded-sm border-none bg-gray-100 shadow-sm"
                  value={filterText}
                  onChange={handleInputChange}
                  placeholder="Search..."
                />
                <div className="max-h-40 w-full self-start overflow-auto">
                  {filteredOptions.length > 0 ? (
                    filteredOptions?.map((option, index) => (
                      <div
                        key={index}
                        className="group flex w-full cursor-pointer items-center gap-x-2 p-2 hover:bg-secondary/10 "
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option.image !== null || option.image !== "" ? (
                          <>
                          {
                            option.label === "All" ? "" :  <img src={option.image} alt={option.label} className="h-6 w-6 rounded-full" /> 
                          }
                          </>
                         
                        ) : (
                          ""
                        )}

                        <span className="text-sm font-semibold text-primary-color-100 group-hover:text-secondary">{option.label}</span>
                      </div>
                    ))
                  ) : (
                    <span className="mt-12 block text-center text-sm font-semibold text-gray-400">Not Found</span>
                  )}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default SupportFilter;

import * as auth from "./auth";
import * as users from "./users";
import * as profile from "./profile";
import * as settings from "./settings";
import * as payments from "./payments";
import * as dashboard from "./dashboard";
import * as analytics from "./analytics";
import * as supportTickets from "./supportTickets";
import * as setting_profile from "./setting_profile";
import * as subscriptionPlans from "./subscriptionPlans";
export default {
  auth,
  users,
  profile,
  payments,
  settings,
  analytics,
  dashboard,
  supportTickets,
  setting_profile,
  subscriptionPlans,
};

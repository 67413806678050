import moment from "moment";
import { useState } from "react";
import Lottie from "react-lottie";
import { Tooltip } from "react-tooltip";
import Badge from "../components/Badge";
import ApiServices from "../api/services";
import Heading from "../components/Heading";
import { Fragment, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import TitleValue from "../components/TitleValue";
import ReceiptModal from "../components/ReciptModal";
import { useLoggedContext } from "../context/provider";
import { mediaUrl, userMediaUrl } from "../utils/config";
import { useLocation, useParams } from "react-router-dom";
import { useSortableData } from "../hooks/useSortableData";
import animationData from "../assets/lotties/empty-folder";
import UpdateTrialModal from "../components/UpdateTrialModal";
import ConfirmationModal from "../components/ConfirmationModal";
import AddNewSettingModal from "../components/AddNewSettingModal";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

// Table Head
const TABLE_HEAD_LOGIN = ["Names", "Login at"];
const TABLE_HEAD = ["Invoice ID", "Amount", "Plan", "Status", "Received at", "Action"];

const SettingDetail = () => {
  // Context
  const { user, loading, setLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // React Router Hook
  const location = useLocation();
  const { id: paramId } = useParams();
  const { settingDetail } = location.state || {};

  const [settingDetailData, setSettingDetailData] = useState(null);
  const [openBlockModal, setOpenBlockModal] = useState({ open: false, name: "" });
  const [openReciptModal, setOpenReciptModal] = useState({ open: false, data: null });
  const [openUpdateTrialModal, setOpenUpdateTrialModal] = useState({ open: false, id: "" });
  const [openEndTrialModal, setOpenEndTrialModal] = useState({ open: false, id: "" });
  const [openAcceptDeclineModal, setOpenAcceptDeclineModal] = useState({ open: false, label: "", name: "" });
  const [openAddNewSettingModal, setOpenAddNewSettingModal] = useState({ open: false, data: null, editMode: false });

  const transactions = settingDetailData?.user?.transaction;

  // Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(settingDetailData?.login_history);

  const {
    items: transactionHistoryList,
    requestSort: transactionHistoryListSort,
    sortConfig: transactionHistoryListSortConfig,
  } = useSortableData(transactions);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Block Setting
  const blockSetting = () => {
    setBtnLoading(true);
    let id = settingDetailData?.user?.id || paramId;
    let settingMessage =
      settingDetailData?.user?.status === 0 ? `${openBlockModal.name} has been Activated` : `${openBlockModal.name} has been Deactivated`;

    ApiServices.settings
      .blockSetting(id)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          // getAllSettings();
          getSettingDetail();
          setOpenBlockModal({ open: false, name: "" });
          openSuccessModal({
            title: "Success!",
            message: settingMessage,
            onPress: (close) => {
              close();
              // getAllSettings();
              getSettingDetail();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Accept Decline
  const acceptDeclineSetting = () => {
    setBtnLoading(true);
    let settingMessage =
      openAcceptDeclineModal.label === "accept"
        ? `${openAcceptDeclineModal.name} has been Activated`
        : `${openAcceptDeclineModal.name} has been Declined`;
    let settingStatus = openAcceptDeclineModal.label === "accept" ? `Accept` : `Reject`;
    let settingId = settingDetailData?.user?.id || paramId;

    let formData = new FormData();
    formData.append("status", settingStatus);
    formData.append("id", settingId);

    ApiServices.settings
      .acceptDeclineSetting(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          // setActiveTab(0);
          // getAllSettings();
          // getPendingSettingsGlobal();
          // getPendingSettings();
          // getRejectSettings();
          getSettingDetail();
          setOpenAcceptDeclineModal({ open: false, label: "" });
          openSuccessModal({
            title: "Success!",
            message: settingMessage,
            onPress: (close) => {
              close();
              // setActiveTab(0);
              // getAllSettings();
              // getPendingSettingsGlobal();
              // getPendingSettings();
              getSettingDetail();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get setting detail
  const getSettingDetail = () => {
    setLoading(true);
    const payload = {
      user_id: settingDetail?.user?.id ? settingDetail?.user?.id : paramId,
    };
    ApiServices.setting_profile
      .getSettingProfile(payload)
      .then((res) => {
        const { data, message } = res;
        console.log("Setting Detail", data);
        if (data.code === 200) {
          setSettingDetailData(data?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // useEffects
  useEffect(() => {
    getSettingDetail();
  }, []);

  // Short property for profile data
  const subscriptionData =
    settingDetailData && settingDetailData.user.subscription && settingDetailData.user.subscription[0] && settingDetailData.user.subscription[0];
  const nurseryData = settingDetailData && settingDetailData.user.nursery && settingDetailData.user.nursery[0] && settingDetailData.user.nursery[0];

  const hasPermissions = user?.permissions?.includes("super_panel_accept_decline_request") || user?.role === "super_admin";

  // Calculate remaining days
  const currentDate = moment();
  const endDate = moment.unix(subscriptionData?.end_date);
  const remainingDays = endDate.diff(currentDate, "days");
  return (
    <>
      <div className="grid grid-cols-12 gap-5 ">
        <div className="col-span-5 space-y-4 3xl:space-y-5">
          <div className="space-y-6 card ">
            <div className="flex items-baseline justify-between ">
              <Heading heading={nurseryData?.name} />
              {nurseryData?.status === "Pending" || nurseryData?.status === "Reject" ? (
                ""
              ) : (
                <div className="flex items-center gap-x-3">
                  {(user?.permissions?.includes("super_panel_edit_setting") || user?.role === "super_admin") && (
                    <button
                      className="text-sm font-medium border-b border-secondary text-secondary"
                      type="button"
                      onClick={() => {
                        setOpenAddNewSettingModal({ open: true, data: settingDetailData?.user, editMode: true });
                      }}
                    >
                      Edit
                    </button>
                  )}
                  {(user?.permissions?.includes("super_panel_block_unblock_setting") || user?.role === "super_admin") && (
                    <button
                      className={`border-b text-sm  font-medium ${settingDetailData?.user?.status === 1 ? "border-red-500 text-red-500" : "border-accent text-accent"}`}
                      type="button"
                      onClick={() => setOpenBlockModal({ open: true, name: nurseryData?.name })}
                    >
                      {settingDetailData?.user?.status === 0 ? "Activate" : "Deactivate"}
                    </button>
                  )}
                </div>
              )}
            </div>
            {loading ? (
              <Skeleton count={1} height={480} />
            ) : (
              <div>
                <div className="space-y-5">
                  <div className="sticky top-0 flex flex-col items-center justify-center gap-5 bg-white ">
                    <div className="w-24 h-24 rounded 3xl:h-40 3xl:w-40">
                      <img src={mediaUrl + nurseryData?.logo || images.placeholder} className="object-contain w-full h-full rounded" alt="icon" />
                    </div>
                    <Badge status={settingDetailData?.user?.status === 0 ? "Deactive" : "Active"} />

                    {/* <div className="space-y-2">
                      <div className="flex items-center gap-x-4">
                        <h2 className="text-lg font-semibold text-black">{nurseryData?.name}</h2>
                        <Badge status={settingDetailData?.user?.status === 0 ? "Deactive" : "Active"} />
                      </div>
                    </div> */}
                  </div>

                  <div className="grid grid-cols-12 py-6 pl-3 rounded-lg gap-y-4 gap-x-4">
                    <div className="col-span-6">
                      <TitleValue title="Contact Person" value={nurseryData?.contact_person_name} />
                    </div>
                    {/* <div className="col-span-6">
                    <TitleValue title="No. of Employees" value={nurseryData?.users_count} />
                  </div> */}
                    <div className="col-span-6">
                      <TitleValue title="Email" value={settingDetailData?.user?.email} />
                    </div>
                    <div className="col-span-6">
                      <TitleValue title="Phone" value={nurseryData?.phone_number} />
                    </div>

                    <div id="date" className="col-span-6">
                      <TitleValue title="Joined at " value={moment.unix(nurseryData?.created_at).format("D MMM YYYY")} />
                    </div>
                    <Tooltip
                      anchorSelect="#date"
                      id="date-tooltip"
                      offset={5}
                      className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                      classNameArrow="hidden"
                      effect="solid"
                      place="bottom"
                    >
                      {/* Tooltip Content */}
                      <span>{moment.unix(nurseryData?.created_at).format("D MMM YYYY h:mm A")}</span>
                    </Tooltip>
                    <div className="col-span-6">
                      <TitleValue title="Last update by " value={nurseryData?.update_by || "N/A"} lastUpdated />{" "}
                    </div>
                    {nurseryData?.country !== "undefined" ? (
                      <div className="col-span-6">
                        <TitleValue title="Country" value={nurseryData?.country} />
                      </div>
                    ) : (
                      <div className="col-span-6">
                        <TitleValue title="Country" value="N/A" />
                      </div>
                    )}

                    <div className="col-span-6">
                      <TitleValue title="Address" value={nurseryData?.address} />
                    </div>
                  </div>
                </div>

                {(nurseryData?.status === "Pending" || nurseryData?.status === "Reject") && hasPermissions && (
                  <div className="sticky bottom-0 px-6 pt-4 space-y-3 bg-white">
                    <button
                      className="w-full py-3 text-sm font-medium text-white rounded-full bg-accent hover:opacity-75"
                      type="button"
                      onClick={() => setOpenAcceptDeclineModal({ open: true, label: "accept", name: nurseryData?.name })}
                    >
                      Accept
                    </button>

                    <button
                      className="w-full py-3 text-sm font-medium text-red-500 transition-colors duration-300 border border-red-500 rounded-full hover:bg-red-500 hover:text-white "
                      type="button"
                      onClick={() => setOpenAcceptDeclineModal({ open: true, label: "decline", name: nurseryData?.name })}
                    >
                      Decline
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Last Login */}
          <div className="space-y-4 card">
            <Heading heading="Last Login" />
            <div className="-mx-5 overflow-x-auto overflow-y-auto h-72 ">
              <table className="w-full text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD_LOGIN.map((head) => (
                      <th
                        key={head}
                        className="p-4 bg-white border-b border-gray-100 first:pl-6"
                        onClick={() => {
                          let sortKey;
                          if (head === "Name") {
                            sortKey = "employee.name";
                          } else if (head === "Login at") {
                            sortKey = "created_at_unix";
                          } else {
                            sortKey = head.toLowerCase();
                          }
                          requestSort(sortKey);
                        }}
                      >
                        <p className="text-xs font-semibold leading-5  whitespace-nowrap font-inter 3xl:text-sm">
                          {head}
                          {sortConfig.key === (head === "Name" ? "employee.name" : head === "Login at" ? "created_at_unix" : head.toLowerCase()) &&
                          sortConfig.direction === "asc" ? (
                            <ChevronUpIcon className="inline-block h-4 w-3.5" />
                          ) : (
                            <ChevronDownIcon className="inline-block h-4 w-3.5" />
                          )}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="2">
                        <Skeleton count={4} height={50} />
                      </td>
                    </tr>
                  ) : items?.length > 0 ? (
                    items?.map((item, index) => (
                      <tr key={index} className="even:bg-gray-50">
                        <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                          <div className="flex items-center cursor-pointer">
                            <div className="w-8 h-8 mr-3 ">
                              <img
                                className="object-contain w-8 h-8 mr-3"
                                src={item?.employee?.profile_image_url ? userMediaUrl + item?.employee?.profile_image_url : images.placeholder}
                                alt="Profile"
                              />
                            </div>
                            <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee?.name || "N/A"}</p>
                          </div>
                        </td>
                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <p id={`loginDate${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                            {moment.unix(item?.created_at_unix).format("D MMM YYYY h:mm A")}
                          </p>
                          {/* <Tooltip
                            anchorSelect={`#loginDate${index + 1}`}
                            id={`date-tooltip${index + 1}`}
                            offset={5}
                            className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                            classNameArrow="hidden"
                            effect="solid"
                            place="bottom"
                          >
                            <span>{moment.unix(item?.created_at_unix).format("D MMM YYYY h:mm A")}</span>
                          </Tooltip> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    // Render "No Data" message for active driver
                    <tr className="h-52">
                      <td colSpan="6">
                        <Lottie options={emptyFolderAnimation} width={200} height={200} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex flex-col col-span-7 space-y-4 3xl:space-y-5">
          {/* Subscription Detail */}
          <div className="space-y-6 card ">
            <Heading heading="Subscription Details" />
            {loading ? (
              <Skeleton count={1} height={100} />
            ) : subscriptionData?.plan_name ? (
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-2">
                    <h2 className="text-xl font-semibold 3xl:text-22 text-secondary">{`${subscriptionData?.plan_name || "N/A"} Plan`} </h2>

                    {settingDetailData?.user?.free_trial === 1 && (
                      <Badge
                        status={remainingDays > 0 ? "active" : "deactive"}
                        label={remainingDays > 0 ? `${remainingDays} Days Free Trial Remaining` : "Last day of Trial"}
                      />
                    )}
                  </div>

                  {/* Buttons */}
                  <div className="space-y-3">
                    <button
                      onClick={() => setOpenUpdateTrialModal({ open: true, id: settingDetailData?.user?.id })}
                      className="text-sm font-medium underline text-secondary underline-offset-2 3xl:text-base"
                      type="button"
                    >
                      Update Trial Period
                    </button>

                    <h2
                      onClick={() => setOpenEndTrialModal({ open: true, id: settingDetailData?.user?.id })}
                      className="text-sm font-medium text-red-500 underline cursor-pointer underline-offset-2 3xl:text-base"
                    >
                      End Free Trial
                    </h2>
                  </div>
                </div>
                {subscriptionData?.price && subscriptionData?.end_date ? (
                  <h2 className="flex text-xs gap-x-1 text-primary-color-400 xl:text-xs 2xl:text-sm">
                    Next Payment: <span className="font-medium">£{subscriptionData?.price || "00"}</span> on
                    <span className="font-medium">
                      <p id={`trial-date`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                        {subscriptionData?.end_date ? moment.unix(parseFloat(subscriptionData?.end_date)).format("D MMM YYYY") : "N/A"}
                      </p>
                    </span>
                  </h2>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <h2 className="mx-auto text-lg italic font-medium text-secondary">You have not subscribed to any package yet.</h2>
            )}
          </div>

          {/* Transaction History */}
          <div className="flex-grow space-y-4 card">
            <Heading heading="Transactions History " />
            <div className="-mx-5 mb-8 h-96 overflow-auto 3xl:h-[550px]">
              <table className="w-full text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="p-4 bg-white border-b border-gray-100 first:pl-6"
                        onClick={() => {
                          let sortKey;
                          if (head === "Transaction ID") {
                            sortKey = "transaction_id";
                          } else if (head === "Amount") {
                            sortKey = "price";
                          } else if (head === "Subscription Package") {
                            sortKey = "plan_name";
                          } else if (head === "Status") {
                            sortKey = "payment_status";
                          } else {
                            sortKey = head.toLowerCase();
                          }
                          transactionHistoryListSort(sortKey);
                        }}
                      >
                        <p className="text-xs font-semibold leading-5 cursor-pointer whitespace-nowrap font-inter 3xl:text-sm">
                          {head}
                          {transactionHistoryListSortConfig.key ===
                            (head === "Transaction ID"
                              ? "transaction_id"
                              : head === "Amount"
                                ? "price"
                                : head === "Subscription Package"
                                  ? "plan_name"
                                  : head === "Status"
                                    ? "payment_status"
                                    : head.toLowerCase()) && transactionHistoryListSortConfig.direction === "asc" ? (
                            <ChevronUpIcon className="inline-block h-4 w-3.5" />
                          ) : (
                            <ChevronDownIcon className="inline-block h-4 w-3.5" />
                          )}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6">
                        <Skeleton count={4} height={50} />
                      </td>
                    </tr>
                  ) : transactionHistoryList?.length > 0 ? (
                    transactionHistoryList.map((item, index) => (
                      <tr key={index} className="even:bg-gray-50">
                        <td className="py-3 pl-6">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.transaction_id || "N/A"}</p>
                        </td>
                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{`£${item?.price}`}</p>
                        </td>

                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{`${item?.plan_name}/${item?.plan_type}`}</p>
                        </td>

                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <Badge status={item?.payment_status} />
                        </td>

                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                            {moment.unix(item?.created_at_unix).format("D MMM YYYY")}
                          </p>
                          <Tooltip
                            anchorSelect={`#date${index + 1}`}
                            id={`date-tooltip${index + 1}`}
                            offset={5}
                            className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                            classNameArrow="hidden"
                            effect="solid"
                            place="bottom"
                          >
                            {/* Tooltip Content */}
                            <span>{moment.unix(item?.created_at_unix).format("D MMM YYYY h:mm A")}</span>
                          </Tooltip>
                        </td>
                        {(item?.payment_status !== "failed" || item?.payment_status !== "Failed") && (
                          <td onClick={() => setOpenReciptModal({ open: true, data: item })} className="py-3 pl-4 pr-3 3xl:px-4">
                            <p className="text-xs font-medium underline cursor-pointer text-secondary 3xl:text-sm">Receipt</p>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    // Render "No Data" message for active driver
                    <tr className="h-52">
                      <td colSpan="6">
                        <Lottie options={emptyFolderAnimation} width={200} height={200} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Block Modal */}
      <ConfirmationModal
        isOpen={openBlockModal.open}
        handleSubmit={blockSetting}
        setIsOpen={(open) => setOpenBlockModal((prev) => ({ ...prev, open }))}
        label={settingDetailData?.user?.status === 1 ? `Deactivate ${openBlockModal?.name}` : `Activate ${openBlockModal?.name}`}
      />

      {/* Accept Decline Modal */}
      <ConfirmationModal
        isOpen={openAcceptDeclineModal.open}
        label={openAcceptDeclineModal.label}
        name={openAcceptDeclineModal.name}
        handleSubmit={acceptDeclineSetting}
        setIsOpen={(open) => setOpenAcceptDeclineModal((prev) => ({ ...prev, open }))}
      />

      {/* Update Trial Modal */}
      <UpdateTrialModal
        userId={openUpdateTrialModal.id}
        onSuccess={getSettingDetail}
        isOpen={openUpdateTrialModal.open}
        setIsOpen={(open) => setOpenUpdateTrialModal((prev) => ({ ...prev, open }))}
      />

      {/* End Trial Modal */}
      <ConfirmationModal
        isOpen={openEndTrialModal.open}
        label="end free trial"
        titleMaxWidth="max-w-xs"
        // handleSubmit={acceptDeclineSetting}
        setIsOpen={(open) => setOpenEndTrialModal((prev) => ({ ...prev, open }))}
      />
      {/* Add New Setting Modal */}
      <AddNewSettingModal
        onSuccess={() => {
          getSettingDetail();
        }}
        editMode={openAddNewSettingModal.editMode}
        isOpen={openAddNewSettingModal.open}
        data={openAddNewSettingModal.data}
        setIsOpen={(open) => setOpenAddNewSettingModal((prev) => ({ ...prev, open }))}
      />

      {/* Recipt modal */}
      <ReceiptModal
        data={openReciptModal.data}
        isOpen={openReciptModal.open}
        setIsOpen={(open) => setOpenReciptModal((prev) => ({ ...prev, open }))}
      />
    </>
  );
};

export default SettingDetail;

import Spinner from "./Spinner";
import { Fragment } from "react";
import { images } from "../assets/styles/Images";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function ConfirmationModal({ label, innerLabel, isOpen, setIsOpen, handleSubmit, data, labelTwo, name, titleMaxWidth }) {
  // Context
  const { btnLoading } = useLoggedContext();
  const lowerCaseLabel = label?.toLowerCase();

  const redLabels =
    lowerCaseLabel === "decline" ||
    lowerCaseLabel.includes("end") ||
    lowerCaseLabel === "deactivate" ||
    lowerCaseLabel === "deactive" ||
    lowerCaseLabel === "remove" ||
    lowerCaseLabel === "delete" ||
    lowerCaseLabel === "cancel" ||
    lowerCaseLabel === "close";
  const greenLabels = lowerCaseLabel === "accept" || lowerCaseLabel === "active" || lowerCaseLabel === "re-open" || lowerCaseLabel === "activate";

  const backgroundColor = `${greenLabels ? "bg-accent hover:bg-accent" : redLabels ? "bg-red-500 hover:bg-red-500" : "bg-secondary hover:bg-secondary"}`;
  const hoverBackground = `${greenLabels ? "hover:bg-accent" : redLabels ? "hover:bg-red-500" : "hover:bg-secondary"}`;
  const borderColor = `${greenLabels ? "border-accent" : redLabels ? "border-red-500" : "border-secondary"}`;
  const textColor = `${greenLabels ? "text-accent" : redLabels ? "text-red-500" : "text-secondary"}`;

  const closeModal = () => setIsOpen(false);

  const getButtonStyles = (buttonType) => {
    const baseStyles =
      "inline-flex w-full justify-center rounded-full px-4 py-2.5 text-base font-medium transition-all duration-300 focus:outline-none";
    switch (buttonType) {
      case "Yes":
        return `${backgroundColor} text-white ${baseStyles} ${btnLoading ? "" : "hover:opacity-75"}`;
      case "No":
        return `border ${borderColor} ${textColor} bg-white ${hoverBackground} hover:text-white ${baseStyles}`;
      default:
        return "";
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-md p-8 overflow-hidden text-center align-middle transition-all bg-white shadow-xl rounded-2xl">
                  <div onClick={closeModal} className="float-right">
                    <XMarkIcon className="cursor-pointer h-7 w-7 text-secondary-color" />
                  </div>
                  <img
                    src={
                      images[greenLabels ? "QuestionGreen" : redLabels ? "QuestionRed" : label === "Questionnaire Form" ? data.image : "QuestionBlue"]
                    }
                    alt="question mark icon"
                    className="mx-auto"
                  />
                  <Dialog.Title
                    as="h3"
                    className={`mx-auto mt-10 ${titleMaxWidth ? titleMaxWidth : "max-w-[25rem]"} font-inter text-lg font-semibold leading-7 text-secondary 3xl:text-xl `}
                  >
                    Are you sure you want to {label} {labelTwo}
                    <span className={`${label === "Questionnaire Form" || label === "Delete" ? "text-red-500 " : "text-secondary"}`}>{name}</span>
                    <span className="underline">{data}</span>?
                  </Dialog.Title>
                  {innerLabel && <p className="text-sm font-semibold text-red-500">({innerLabel})</p>}
                  <div className="flex px-8 mt-12 gap-x-6">
                    <button type="button" className={getButtonStyles("Yes")} onClick={handleSubmit || closeModal}>
                      {btnLoading ? <Spinner /> : "Yes"}
                    </button>

                    <button type="button" className={getButtonStyles("No")} onClick={closeModal}>
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

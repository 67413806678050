import { axios } from "../axios";
import { POST, GET, DELETE } from "../endpoints";

export const AddNewUser = async (formData) => {
  try {
    let response = await axios.post(POST.ADD_NEW_USER, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getRoles = async (data) => {
  try {
    let response = await axios.get(GET.GET_ROLE, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getUsers = async (data) => {
  try {
    let response = await axios.get(GET.GET_ALL_USERS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};


export const deleteUser = async (formData) => {
  try {
    let response = await axios.post(POST.DELETE_USER, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateNewUser = async (id, formData) => {
  try {
    let response = await axios.post(`${POST.UPDATE_NEW_USER}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

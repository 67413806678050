import moment from "moment";
import Lottie from "react-lottie";
import Tabs from "../components/Tabs";
import { Tooltip } from "react-tooltip";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import RangePicker from "../components/RangePicker";
import { useLoggedContext } from "../context/provider";
import FilterSelect from "../components/FilterSelect";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import UpdateTrialModal from "../components/UpdateTrialModal";
import SubscriptionWidget from "../components/SubscriptionWidget";
import { ArrowPathIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

// Table Head
const TABLE_HEAD_HISTORY = ["Package", "Price", "Discount", "Updated At"];
const TABLE_HEAD_PLAN = ["Package", "Users", "Monthly Price", "Yearly Discount", "Yearly Price", "Action"];

// Tabs
const tabs = [{ label: "Monthly" }, { label: "Yearly" }];

const SubscriptionPlans = () => {
  const { getPendingSettingsGlobal } = useLoggedContext();

  const todayStartDate = moment.utc().startOf("day");
  const todayEndDate = moment.utc().endOf("day");

  const [loading, setLoading] = useState(false);
  const [planLoading, setPlanLoading] = useState(false);
  const [freeTrialDay, setFreeTrialDay] = useState("");
  const [historyLoading, setHistoryLoading] = useState(false);
  const [subscriptionPlansListing, setSubscriptionPlansListing] = useState([]);
  const [openUpdateTrialModal, setOpenUpdateTrialModal] = useState({ open: false, label: "" });
  const [subPlansHistory, setSubPlansHistory] = useState([]);

  const [totalPageTransactionHistory, setTotalPageTransactionHistory] = useState(0);
  const [currentPageTransactionHistory, setCurrentPageTransactionHistory] = useState(1);

  const [activeSubscriptionPlanTab, setActiveSubscriptionPlanTab] = useState(tabs[0].label);

  const [editedValues, setEditedValues] = useState({});

  const [focusedRow, setFocusedRow] = useState(null);

  const [dateRange, setDateRange] = useState({
    startDate: todayStartDate.unix(),
    endDate: todayEndDate.unix(),
  });

  // Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(subPlansHistory);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 10 : isLaptopMedium ? 9 : isLaptop ? 9 : 8;

  // Pagination
  const handleAllUsersPageChange = ({ selected }) => {
    setCurrentPageTransactionHistory(selected + 1);
  };

  // Handle Edit
  const handleEdit = (key, value) => {
    if (focusedRow !== null) {
      setEditedValues((prevValues) => ({
        ...prevValues,
        [focusedRow]: { ...prevValues[focusedRow], [key]: value },
      }));
    }
  };

  // Get Subscription Plans
  const getPlanDetails = () => {
    setLoading(true);
    setPlanLoading(true);
    ApiServices.subscriptionPlans
      .getSubscriptionPlans()
      .then((res) => {
        const { data, message } = res;

        if (data?.code === 200) {
          setSubscriptionPlansListing(data?.data);
          setFreeTrialDay(data?.data[0]?.free_trial_days);
          let newEditedValues = {};

          data?.data?.forEach((item) => {
            if ([1, 3, 5, 7].includes(item.id)) {
              newEditedValues[item?.id] = {
                monthly_price: item.price,
                yearly_discount: item.discount_percentage,
              };
            }
          });
          setEditedValues(newEditedValues);

          setLoading(false);
          setPlanLoading(false);
        } else {
          setLoading(false);
          setPlanLoading(false);
          setSubscriptionPlansListing([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubscriptionPlansListing([]);
      });
  };

  // Get Subscription Plans History
  const getSubscriptionPlansHistory = () => {
    setHistoryLoading(true);
    let payload = {
      page: currentPageTransactionHistory,
      records_no: itemsPerPage,

      start_date: dateRange.startDate ? dateRange.startDate : null,
      end_date: dateRange.endDate ? dateRange.endDate : null,
    };

    setHistoryLoading(true);
    ApiServices.subscriptionPlans
      .getSubscriptionPlansHistory(payload)
      .then((res) => {
        const { data, message } = res;
        if (data?.code === 200) {
          setSubPlansHistory(data?.data?.data);
          setCurrentPageTransactionHistory(data?.data?.current_page);
          setTotalPageTransactionHistory(Math.ceil(data?.data?.total / data?.data?.per_page));

          setHistoryLoading(false);
        } else {
          setSubPlansHistory([]);
          setHistoryLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubPlansHistory([]);
      });
  };

  // Handle Save
  const handleSave = (id) => {
    const payload = {
      price: editedValues[id]?.monthly_price,
      discount_percentage: editedValues[id]?.yearly_discount,
    };

    setPlanLoading(true);
    ApiServices.subscriptionPlans
      .updateSubscriptionPerUserPrice(payload, id)
      .then((res) => {
        const { data, message } = res;
        if (data?.code === 200) {
          getPlanDetails();
          getSubscriptionPlansHistory();
          setPlanLoading(false);
          setFocusedRow(null);
        } else {
          getSubscriptionPlansHistory();
          getPlanDetails();
          setPlanLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        getPlanDetails();
        setPlanLoading(false);
      });
  };

  // Use Effects
  useEffect(() => {
    getPlanDetails();
  }, []);

  useEffect(() => {
    getSubscriptionPlansHistory();
  }, [currentPageTransactionHistory, dateRange]);

  useEffect(() => {
    getPendingSettingsGlobal();
  }, []);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="grid min-h-[83vh] gap-5 md:grid-cols-1 xl:grid-cols-12">
        <div className="card col-span-12 space-y-12 xl:col-span-7 2xl:col-span-8">
          <div>
            <div className="flex">
              <Heading
                heading="Plans"
                subheading="Subscriptions plans details."
                icon
                handleRefresh={() => {
                  getPlanDetails();
                }}
              />
            </div>
            <div className="pt-2">
              <div className="-mx-6 mb-8 overflow-x-auto">
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD_PLAN?.map((head) => (
                        <th
                          key={head}
                          className="border-b border-gray-100 bg-white p-4 first:pl-6 last:pl-14"
                          onClick={() => {
                            let sortKey;
                            if (head === "Package") {
                              sortKey = "plan_name";
                            } else if (head === "Users") {
                              sortKey = "users";
                            } else if (head === "Monthly Price") {
                              sortKey = "monthly_price";
                            } else if (head === "Yearly Discount") {
                              sortKey = "yearly_discount";
                            } else if (head === "Yearly Price") {
                              sortKey = "yearly_price";
                            } else {
                              sortKey = head.toLowerCase();
                            }
                            requestSort(sortKey);
                          }}
                        >
                          <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                            {head}
                            {sortConfig.key ===
                              (head === "Package"
                                ? "plan_name"
                                : head === "Users"
                                  ? "users"
                                  : head === "Monthly Price"
                                    ? "monthly_price"
                                    : head === "Yearly Discount"
                                      ? "yearly_discount"
                                      : head === "Yearly Price"
                                        ? "yearly_price"
                                        : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                              <>{head === "Action" ? "" : <ChevronUpIcon className="inline-block h-4 w-3.5" />}</>
                            ) : (
                              <>{head === "Action" ? "" : <ChevronDownIcon className="inline-block h-4 w-3.5" />}</>
                            )}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {planLoading ? (
                      <tr>
                        <td colSpan="6">
                          <Skeleton count={4} height={50} />
                        </td>
                      </tr>
                    ) : subscriptionPlansListing?.length > 0 ? (
                      subscriptionPlansListing
                        .filter((item) => item?.type === "Monthly" && item.name !== "Free Trial")
                        .map((item, index) => (
                          <tr
                            key={item?.id}
                            className={`transition-all duration-300 ${
                              item.name === "Basic"
                                ? "bg-[#FEF9F2]"
                                : item.name === "Premium"
                                  ? "bg-[#EAFCF3]"
                                  : item.name === "Enterprise"
                                    ? "bg-[#FFEFEB]"
                                    : item.name === "Ultra"
                                      ? "bg-[#EAEDEE]"
                                      : "bg-gray-100"
                            }`}
                          >
                            <td className="py-4 pl-6 3xl:pl-6 3xl:pr-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.name}</p>
                            </td>

                            <td className="py-4 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{`${item?.user_count_min}-${item?.user_count_max}`}</p>
                            </td>
                            <td className="py-4 pl-4 3xl:px-4">
                              <div className="relative">
                                <input
                                  type="text"
                                  value={editedValues[item.id]?.monthly_price || ""}
                                  placeholder="Price"
                                  onChange={(e) => handleEdit("monthly_price", e.target.value)}
                                  onFocus={() => setFocusedRow(item?.id)}
                                  className="h-8 max-w-[98px] rounded-full border-grayColor200 indent-3 placeholder:text-sm"
                                />
                                <div className="absolute left-2 top-1 2xl:top-1.5 3xl:top-1">£</div>
                              </div>
                            </td>
                            <td className="py-4 pl-4 3xl:px-4">
                              <div className="relative max-w-[98px]">
                                <input
                                  type="text"
                                  value={editedValues[item.id]?.yearly_discount || ""}
                                  placeholder="Discount"
                                  onChange={(e) => handleEdit("yearly_discount", e.target.value)}
                                  onFocus={() => setFocusedRow(item?.id)}
                                  className="relative h-8 max-w-[98px] rounded-full border-grayColor200 placeholder:text-sm"
                                />
                                {/* <div className="absolute bg-white ps-2 right-3 top-1">%</div> */}
                              </div>
                            </td>

                            <td className="flex items-center justify-start gap-x-1 py-6 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                £
                                {/* {Math.round(editedValues[item.id]?.monthly_price * (1 - editedValues[item.id]?.yearly_discount / 100) * 12 * 100) /
                                  100} */}
                                {/* {editedValues[item.id]?.monthly_price * 12 - editedValues[item.id]?.yearly_discount} */}
                                {(editedValues[item.id]?.monthly_price * 12 - editedValues[item.id]?.yearly_discount).toFixed(2)}
                              </p>
                              <div className="">
                                <img src={images.HistoryInfo} alt="info" id={`info-${item.id}`} className="h-5 w-5 cursor-pointer" />
                                <Tooltip
                                  anchorSelect={`#info-${item.id}`}
                                  id={`date-tooltip-info-${item.id}`}
                                  offset={5}
                                  className="z-10 border border-gray-100 !bg-white text-sm !text-primary-color-100 !opacity-100 shadow-card"
                                  classNameArrow="hidden"
                                  effect="solid"
                                  place="bottom"
                                >
                                  {/* Tooltip Content */}
                                  <span>
                                    £{editedValues[item.id]?.monthly_price} * 12 months = £{editedValues[item.id]?.monthly_price * 12}{" "}
                                  </span>
                                  <br />
                                  <span>
                                    £{editedValues[item.id]?.monthly_price * 12} - {editedValues[item.id]?.yearly_discount} = £
                                    <b>
                                      {Math.round((editedValues[item.id]?.monthly_price * 12 - editedValues[item.id]?.yearly_discount) * 100) / 100}
                                    </b>
                                  </span>
                                </Tooltip>
                              </div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="flex items-center gap-x-4">
                                <div
                                  className={`cursor-pointer items-center gap-x-1 whitespace-nowrap text-xs font-normal ${focusedRow === item?.id ? "text-accent" : "text-primary-color-200"} underline underline-offset-2 3xl:text-sm `}
                                  onClick={() => handleSave(item?.id)}
                                >
                                  {focusedRow === item?.id ? (
                                    <img className="inline-block" src={images.GreenSaveIcon} alt="save" />
                                  ) : (
                                    <img className="inline-block" src={images.SaveIcon} alt="save" />
                                  )}
                                  <span> Save</span>
                                </div>
                                <div
                                  className={`cursor-pointer items-center gap-x-1 whitespace-nowrap text-xs font-normal ${focusedRow === item?.id ? "text-red-500" : "text-primary-color-200"} underline underline-offset-2 3xl:text-sm `}
                                >
                                  {focusedRow === item?.id ? (
                                    <img className="inline-block" src={images.RedCancelIcon} alt="canel" />
                                  ) : (
                                    <img className="inline-block" src={images.CancelIcon} alt="cancel" />
                                  )}
                                  <span>Cancel</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr className="h-[500px]">
                        <td colSpan="6">
                          <Lottie options={emptyFolderAnimation} width={200} height={200} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="space-y-5 px-4">
            <div className="flex items-center justify-between">
              <div className="flex">
                <Heading
                  heading="Preview"
                  subheading="View subscriptions plans as on website."
                  icon
                  handleRefresh={() => {
                    getPlanDetails();
                  }}
                />
              </div>
              <Tabs tabs={tabs} setActiveTab={setActiveSubscriptionPlanTab} activeTab={activeSubscriptionPlanTab} />
            </div>
            <div>
              {loading ? (
                <>
                  <div className="grid grid-cols-4 gap-5">
                    <Skeleton count={1} height={220} style={{ borderRadius: "10px" }} />
                    <Skeleton count={1} height={220} style={{ borderRadius: "10px" }} />
                    <Skeleton count={1} height={220} style={{ borderRadius: "10px" }} />
                    <Skeleton count={1} height={220} style={{ borderRadius: "10px" }} />
                  </div>
                </>
              ) : subscriptionPlansListing && subscriptionPlansListing.length > 0 ? (
                <div className="grid grid-cols-4 gap-5">
                  <div onClick={() => setOpenUpdateTrialModal({ open: true })}>
                    <SubscriptionWidget planName="Trial" planType={`${subscriptionPlansListing[0]?.free_trial_days}-days`} />
                  </div>
                  {subscriptionPlansListing
                    .filter((plan) => {
                      const planType = plan?.type?.toLowerCase();
                      if (activeSubscriptionPlanTab.toLowerCase() === "monthly") {
                        return planType === "monthly";
                      } else if (activeSubscriptionPlanTab.toLowerCase() === "yearly") {
                        return planType === "yearly";
                      }
                      return planType === "monthly";
                    })
                    .map((selectedPlan, index) => (
                      <div key={index}>
                        <SubscriptionWidget
                          planName={selectedPlan?.name}
                          planType={selectedPlan?.type}
                          users={`${selectedPlan?.user_count_min}-${selectedPlan?.user_count_max}`}
                          perUser={selectedPlan?.per_user}
                          price={selectedPlan?.price}
                        />
                      </div>
                    ))}
                </div>
              ) : (
                <div className="flex h-28 items-center justify-center ">
                  <p className="text-center text-base font-medium text-gray-400">No subscription plans available.</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card col-span-12 xl:col-span-5 2xl:col-span-4">
          <div className="flex justify-between">
            <Heading
              heading="History"
              subheading="View plan history of price"
              icon
              handleRefresh={() => {
                getPlanDetails();
              }}
            />

            <RangePicker setRangePicker={setDateRange} width="2xl:w-80 xl:w-76" />
          </div>
          <div className="pt-8 ">
            <div className="-mx-6 mb-8 overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD_HISTORY?.map((head) => (
                      <th
                        key={head}
                        className="border-b border-gray-100 bg-white p-4 first:pl-6"
                        onClick={() => {
                          let sortKey;
                          if (head === "Package") {
                            sortKey = "plan_name";
                          } else if (head === "Price") {
                            sortKey = "price";
                          } else if (head === "Discount") {
                            sortKey = "discount_percentage";
                          } else if (head === "Updated At") {
                            sortKey = "updated_at";
                          } else {
                            sortKey = head?.toLowerCase();
                          }
                          requestSort(sortKey);
                        }}
                      >
                        <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                          {head}
                          {sortConfig.key ===
                            (head === "Package"
                              ? "plan_name"
                              : head === "Price"
                                ? "price"
                                : head === "Discount"
                                  ? "discount_percentage"
                                  : head === "Updated At"
                                    ? "updated_at"
                                    : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                            <ChevronUpIcon className="inline-block h-4 w-3.5" />
                          ) : (
                            <ChevronDownIcon className="inline-block h-4 w-3.5" />
                          )}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {historyLoading ? (
                    <tr>
                      <td colSpan="6">
                        <Skeleton count={itemsPerPage} height={50} />
                      </td>
                    </tr>
                  ) : items?.length > 0 ? (
                    items?.map((item, index) => (
                      <tr
                        key={item?.id}
                        className={`transition-all duration-300 ${
                          item.name === "Basic"
                            ? "bg-[#FEF9F2]"
                            : item.name === "Premium"
                              ? "bg-[#EAFCF3]"
                              : item.name === "Enterprise"
                                ? "bg-[#FFEFEB]"
                                : item.name === "Ultra"
                                  ? "bg-[#EAEDEE]"
                                  : "bg-gray-100"
                        }`}
                      >
                        <td className="py-4 pl-4 3xl:pl-6 3xl:pr-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.name}</p>
                        </td>

                        <td className="py-4 pl-4 3xl:px-4">
                          <p className="text-xs font-normal text-green-500 3xl:text-sm">£{item?.price}</p>
                        </td>
                        <td className="py-4 pl-4 3xl:px-4">
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.discount_percentage}</p>
                        </td>
                        <td className="py-4 pl-4 3xl:px-4">
                          <p id="updated" className="cursor-pointer text-xs font-normal text-primary-color-200 3xl:text-sm">
                            {moment.unix(item?.unix_updated_at).format("D MMM YYYY ")}
                          </p>
                          <Tooltip
                            anchorSelect="#updated"
                            id="updated-tooltip"
                            offset={5}
                            className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                            classNameArrow="hidden"
                            effect="solid"
                            place="bottom"
                          >
                            <span>{moment.unix(item?.unix_updated_at).format("D MMM YYYY h:mm A")}</span>
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className=" h-[500px]">
                      <td colSpan="6">
                        <Lottie options={emptyFolderAnimation} width={200} height={200} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="absolute bottom-4">
              <ReactPaginate
                breakLabel="..."
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                activeClassName="active"
                nextClassName="item next"
                renderOnZeroPageCount={null}
                pageCount={totalPageTransactionHistory}
                breakClassName="item break-me"
                containerClassName="pagination"
                previousClassName="item previous"
                forcePage={currentPageTransactionHistory - 1}
                pageClassName="item pagination-page"
                onPageChange={handleAllUsersPageChange}
                nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </div>
      </div>

      <UpdateTrialModal
        setFreeTrialDay={setFreeTrialDay}
        freeTrialDay={freeTrialDay}
        onSuccess={getPlanDetails}
        subscriptionPlanFreetrial
        isOpen={openUpdateTrialModal.open}
        setIsOpen={(open) => setOpenUpdateTrialModal((prev) => ({ ...prev, open }))}
      />
    </>
  );
};

export default SubscriptionPlans;

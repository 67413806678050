const POST = {
  LOGIN: "employee/login",
  ADD_NEW_USER: "employee/create",
  UPDATE_NEW_USER: "employee/user/update",
  DELETE_USER: "employee/delete_employee",
  UPDATE_NEW_USER: "employee/user/update",
  UPDATE_PROFILE: "employee/update_profile",
  CHANGE_PASSWORD: "employee/reset_password",
  FORGOT_PASSWORD: "employee/forget_password",
  UPDATE_TICKET_RESPONSE: "/employee/response",
  UPDATE_TICKET_STATUS: "/employee/ticket_status",
  UPDATE_ADD_NEW_SETTING: "employee/update_nursery",
  ACCEPT_DECLINE: "/employee/nursery/accept_reject",
  ADD_NEW_SETTING: "employee/nursery/create_by_super_admin",
  UPDATE_SUBSCRIPTION_PER_USER_PRICE: "employee/subscription_plans",
  UPDATE_FREE_TRIAL: "employee/subscription_plan/update_free_trial_days",
  UPDATE_FREE_TRIAL_DATE: "employee/subscibed_plan/update_free_trial_date",
};

const GET = {
  GET_ROLE: "admin/role",
  GET_PROFILE: "employee/get-profile",
  GET_TICKET_DETAILS: "/admin/ticket/",
  GET_SUPPORT_TICKETS: "employee/ticket",
  GET_PAYMENT_HISTORY: "employee/history",
  GET_SETTINGS: "employee/listing_nurseries",
  GET_ALL_USERS: "employee/listing_employees",
  GET_CURRENT_BALANCE: "employee/current_balance",
  BLOCK_SETTING: "employee/nursery_block_unblock",
  GET_SUPPORT_TICKET_COUNTS: "employee/ticket_count",
  GET_SUBSCRIPTION_PLANS: "employee/subscription_plans",
  GET_OVERVIEW: "employee/super_admin/over_view_section_stats",
  GET_ALL_ACTIVE_NURSURIES: "/employee/get_all_active_nurseries",
  GET_SUBSCRIPTION_PLANS_HISTORY: "employee/subscription_plan/history",
  GET_DASHBOARD_SUBSCRIPTION: "employee/super_admin/subscription_stats",
  GET_DASBOARD_SUPPORT_TICKET: "employee/super_admin/support_ticket_count",
  GET_DASHBOARD_SUBSCRIPTION_SALES: "employee/super_admin/subscription_sale",
  GET_DASHBOARD_USERS_STATS: "employee/admin_dashboard/user_states",
  GET_EMPLOYEE_DASHBOARD_STATS: "employee/super_admin/user_stats",
  GET_CAMPUSES_LIST:"employee/campus",
  GET_DEPARTMENT_LIST: "employee/department",
};

const PUT = {
  UPDATE_PASSWORD: "employee/update_password",
};

// const DELETE = {
//   DELETE_USER: "employee/super_admin_user",
// };

export { POST, GET, PUT };

import React from "react";
import { images } from "../assets/styles/Images";

const SubscriptionWidget = ({ planName, users, price, perUser, planType }) => {
  // console.log("🚀 ~ SubscriptionWidget ~ planType:", planName);
  // Function to determine styles based on plan name
  const getPlanStyles = (name) => {
    switch (name?.toLowerCase()) {
      case "basic":
        return {
          backgroundColor: "hover:shadow-basic-subscription bg-[#FEF9F2] hover:border-[#F5B866]",
          textColor: "text-secondary group-hover:text-[#F5B866]",
        };
      case "premium":
        return {
          backgroundColor: "hover:shadow-premium-subscription bg-[#EAFCF3] hover:border-[#53E89E]",
          textColor: "text-secondary group-hover:text-[#53E89E]",
        };
      case "enterprise":
        return {
          backgroundColor: "hover:shadow-enterprise-subscription bg-[#FFEFEB] hover:border-[#F38E74]",
          textColor: "text-secondary group-hover:text-[#F38E74]",
        };
      case "trial":
        return {
          backgroundColor: "hover:shadow-ultra-subscription bg-[#EAEDEE] hover:border-[#264254]",
          textColor: "text-secondary group-hover:text-[#264254]",
        };
    }
  };

  // Get styles based on plan name
  const { backgroundColor: bg, textColor: textCol } = getPlanStyles(planName);

  return (
    <div
      className={`group space-y-6 rounded-xl 2xl:space-y-6 ${bg} ${planName === "Trial" ? "cursor-pointer" : ""} px-2 pb-5 pt-2 text-center transition-all duration-300 hover:border-2 `}
    >
      <div className="flex flex-col items-center space-y-1 py-4">
        <h2 className={`text-2xl font-bold ${textCol} lg:text-2xl xl:text-lg 2xl:text-xl`}>{planName}</h2>
        <div>
          {users && <h3 className="text-base font-medium text-secondary-color lg:text-base xl:text-xs 2xl:text-base">{users} users</h3>}

          {perUser && <h4 className="text-xs text-primary-color-100 2xl:text-sm">from {perUser}* per user</h4>}
        </div>
      </div>
      {planName === "Trial" ? (
        <div className="pt-11">
          <h3 className="text-base font-medium text-secondary lg:text-base xl:text-xs 2xl:text-base">{planType}</h3>
          <h2 className="text-2xl font-bold text-secondary lg:text-xl 2xl:text-2xl 3xl:text-3xl">Free Trial</h2>
        </div>
      ) : (
        <div>
          <h3 className="text-base font-medium text-secondary lg:text-base xl:text-xs 2xl:text-base">{planType}</h3>
          <h2 className="text-2xl font-bold text-secondary lg:text-xl 2xl:text-2xl 3xl:text-3xl">
            {planName === "Basic" ? <span>£{price}</span> : <span>£{price}*</span>}
          </h2>
        </div>
      )}
    </div>
  );
};

export default SubscriptionWidget;

import { useState, useMemo } from "react";

const defaultComparator = (fieldA, fieldB, sortOrder) => {
  if (sortOrder === "asc") {
    return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
  } else {
    return fieldB < fieldA ? -1 : fieldB > fieldA ? 1 : 0;
  }
};

// const sortArray = (array, field, sortOrder, comparator) => {
//   return [...array].sort((a, b) => {
//     let fieldA, fieldB;

//     if (field === "nursery.name") {
//       fieldA = a.nursery?.name?.toLowerCase();
//       fieldB = b.nursery?.name?.toLowerCase();
//     } else {
//       fieldA = a[field];
//       fieldB = b[field];
//     }

//     if (comparator) {
//       return comparator(fieldA, fieldB, sortOrder);
//     }

//     return defaultComparator(fieldA, fieldB, sortOrder);
//   });
// };

const sortArray = (array, field, sortOrder, comparator) => {
  // Check if the array is undefined or null
  if (!Array.isArray(array)) {
    return [];
  }

  return [...array].sort((a, b) => {
    let fieldA, fieldB;

    if (field === "nursery.name") {
      fieldA = a.nursery?.name?.toLowerCase();
      fieldB = b.nursery?.name?.toLowerCase();
    } else if (field === "setting.plan_name") {
      fieldA = a.user?.subscription[0]?.plan_name?.toLowerCase();
      fieldB = b.user?.subscription[0]?.plan_name?.toLowerCase();
    }  else if (field === "setting.end_date") {
      fieldA = a.user?.subscription[0]?.end_date?.toLowerCase();
      fieldB = b.user?.subscription[0]?.end_date?.toLowerCase();
    } else if (field === "setting.created_at_unix") {
      fieldA = a.last_logged_in[0]?.created_at_unix;
      fieldB = b.last_logged_in[0]?.created_at_unix;
    } else {
      fieldA = a[field];
      fieldB = b[field];
    }

    if (comparator) {
      return comparator(fieldA, fieldB, sortOrder);
    }

    return defaultComparator(fieldA, fieldB, sortOrder);
  });
};



export const useSortableData = (items, defaultField = null) => {
  const [sortConfig, setSortConfig] = useState({
    key: defaultField,
    direction: "asc",
  });

  const sortedItems = useMemo(() => {
    return sortArray(items, sortConfig.key, sortConfig.direction);
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

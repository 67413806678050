import { axios } from "../axios";
import { POST, PUT } from "../endpoints";

export const updateProfile = async (formData) => {
  try {
    let response = await axios.post(POST.UPDATE_PROFILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

import moment from "moment";

// Function to convert a date/time format to UTC and then into a Unix milliseconds timestamp
export function toUTCUnixTimestamp(date) {
  return moment(date).utc().valueOf() / 1000;
}

// Function to convert a Unix milliseconds timestamp to a date/time format in local time
export function getLocalDateFromUnixTimestamp(unixTimestamp) {
  return moment(unixTimestamp);
}

export const usersDate = (date) => {
  return moment(date).unix();
};

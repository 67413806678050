import { Fragment, useRef } from "react";
import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import { Dialog, Transition } from "@headlessui/react";
import "react-image-gallery/styles/css/image-gallery.css";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";

const ImagesModal = ({ isOpen, setIsOpen, images = [], selectedImageIndex = 0, imagespdf = [] }) => {
  const galleryRef = useRef(null);

  // Use States
  const [startIndex, setStartIndex] = useState(selectedImageIndex || 0);

  // Close Modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // const handleDownload = async () => {
  //   if (galleryRef.current && images.length > 0) {
  //     const currentIndex = galleryRef.current.getCurrentIndex();
  //     const imageUrl = images[currentIndex].original;

  //     try {
  //       const link = document.createElement("a");
  //       link.href = imageUrl;
  //       link.download = `image-${currentIndex}`;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } catch (error) {
  //       console.error("Error downloading the image:", error);
  //     }
  //   }
  // };

  const handleDownload = async () => {
    if (galleryRef.current && imagespdf.length > 0) {
      const currentIndex = galleryRef.current.getCurrentIndex();
      const fileUrl = imagespdf[currentIndex].original;

      try {
        const link = document.createElement("a");

        // Set up a fetch request with responseType blob
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        // Create a blob URL for the blob object
        const blobUrl = URL.createObjectURL(blob);
        link.href = blobUrl;

        if (fileUrl.endsWith(".pdf")) {
          link.download = `document-${currentIndex}.pdf`;
          link.type = "application/pdf";
        } else {
          link.download = `image-${currentIndex}.jpg`;
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    } else {
      console.error("Gallery reference is not available or there are no images to download.");
    }
  };

    // Render a custom item for PDF
    const renderItem = (item) => {
      if (item.original.endsWith(".pdf")) {
        return (
          <div className="pdf-container pb-8">
            <iframe src={item.original} style={{ width: "100%", height: "65vh" }} frameBorder="0" />
          </div>
        );
      } else {
        return <img src={item.original} alt={item.description} className="image-gallery-image" />;
      }
    };
  
    // Custom Thumbnail Renderer
    const renderThumbInner = (item) => {
      if (item.original.endsWith(".pdf")) {
        return <img src={item?.thumbnail} alt="pdf" className="image-gallery-thumbnail h-16" />;
      } else {
        return <img src={item.thumbnail} alt="image" className="image-gallery-thumbnail" />;
      }
    };


  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
              >
                <Dialog.Panel className="scal h-[95vh] w-[100vw] transform overflow-hidden rounded-2xl bg-white p-8 align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold text-secondary-color">Attachments</h2>

                    <div className="flex items-center justify-end gap-x-3">
                      <h2 className="font-medium underline cursor-pointer text-secondary underline-offset-2" onClick={handleDownload}>
                        Download
                      </h2>
                      <XMarkIcon onClick={closeModal} className="w-8 h-8 cursor-pointer text-primary-color-100" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <ImageGallery
                      ref={galleryRef}
                      className="rounded-lg object-cover"
                      items={images}
                      renderItem={renderItem}
                      renderThumbInner={renderThumbInner}
                      showPlayButton={false}
                      showNav={true}
                      showIndex
                      startIndex={startIndex}
                      showFullscreenButton={false}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ImagesModal;

import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getOverview = async (data) => {
  try {
    let response = await axios.get(GET.GET_OVERVIEW, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getDashboardSupportTicket = async (data) => {
  try {
    let response = await axios.get(GET.GET_DASBOARD_SUPPORT_TICKET, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getDashboardSubscription = async (data) => {
  try {
    let response = await axios.get(GET.GET_DASHBOARD_SUBSCRIPTION, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};


export const getDashboardSubscriptionSales = async (data) => {
  try {
    let response = await axios.get(GET.GET_DASHBOARD_SUBSCRIPTION_SALES, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};